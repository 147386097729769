<template>
  <div>
    <div
      class="
        mobile-header
        d-lg-none
        justify-content-between
        align-items-center
        bg-success
        text-white
        px-2
        py-2
      "
    >
      <router-link to="/" class="mobile-logo-link">
        <img
          src="@/assets/header/logo.png"
          class="mobile-header-logo"
          alt="logo"
        />
      </router-link>

      <div class="icons d-flex flex-row">
        <!-- History -->
        <router-link to="/history" class="mobileNav_link">
          <button class="icon bg-transparent border-0 text-white p-1">
            <i class="fas fa-history fa-2x d-none d-md-block"></i>
            <i class="fas fa-history d-md-none"></i>
            <span class="mobile-nav-name">{{ $t("mobileNav.History") }} </span>
          </button>
        </router-link>
        <!-- Favourites -->
        <router-link to="/favourites" class="mobileNav_link">
          <button
            class="
              icon
              position-relative
              bg-transparent
              border-0
              text-white
              p-1
            "
          >
            <i class="far fa-star fa-2x d-none d-md-block"></i>
            <i class="far fa-star d-md-none"></i>
            <span class="mobile-nav-name">{{
              $t("mobileNav.Favourites")
            }}</span>
            <span
              class="
                favourites-number
                position-absolute
                h3
                font-weight-bold
                bg-white
                rounded-circle
                d-flex
                justify-content-center
                align-items-center
                small
              "
              >{{ favouriteCount }}</span
            >
          </button>
        </router-link>
        <!-- Menu Button -->
        <button
          class="icon bg-transparent border-0 text-white p-1"
          @click="toggleNav"
          type="button"
        >
          <i class="fas fa-bars fa-2x d-none d-md-block"></i>
          <i class="fas fa-bars d-md-none"></i>
          <span class="mobile-nav-name">{{ $t("mobileNav.Menu") }}</span>
        </button>
      </div>
    </div>

    <!-- Toggle navbar -->
    <button
      class="closebtn bg-transparent border-0 text-white"
      @click="toggleNav"
      :class="{ active: isActive }"
    >
      <img src="@/assets/icons/close.png" alt="close" />
    </button>
    <div class="modal-backdrop" v-if="isActive"></div>
    <div class="sidenav d-lg-none" :class="{ open: isActive }">
      <!-- Mobile Nav -->
      <div>
        <ul class="list-unstyled font-weight-bold text-uppercase m-0">
          <li v-for="(nav, index) in mobileNav" :key="index">
            <b-button
              v-if="
                nav.children == null || (nav.children.length == 0 && !nav.href)
              "
              variant="mobile-nav"
              class="
                mobile-nav-button
                w-100
                font-weight-bold
                text-uppercase text-white
                px-0
              "
              :class="`btn-${index}`"
            >
              <router-link :to="nav.route" class="mobileNav_link">
                <div class="text-white bg-mobile-nav px-2">
                  <img
                    :src="nav.icon.mobile"
                    alt="icon"
                    class="nav-icon-mobile"
                  />
                  <span class="h5 pl-1 mb-0">{{ nav.name }}</span>
                </div>
              </router-link>
            </b-button>
            <b-button
              v-else-if="nav.href"
              variant="mobile-nav"
              class="
                mobile-nav-button
                w-100
                font-weight-bold
                text-uppercase text-white
                px-0
              "
              :class="`btn-${index}`"
            >
              <a
                href="http://sunrisedanangvn.com​"
                target="_blank"
                class="mobileNav_link"
              >
                <div class="text-white bg-mobile-nav px-2">
                  <img
                    :src="nav.icon.mobile"
                    alt="icon"
                    class="nav-icon-mobile"
                  />
                  <span class="h5 pl-1 mb-0">{{ nav.name }}</span>
                </div>
              </a>
            </b-button>
            <b-card v-else class="nav__card">
              <div class="card-header text-white bg-mobile-nav pl-2 pr-0 py-2">
                <img
                  :src="nav.icon.mobile"
                  alt="icon"
                  class="nav-icon-mobile"
                />
                <span class="h5 mb-0 pl-1">{{ nav.name }}</span>
              </div>
              <b-button
                v-for="(child, index) in nav.children"
                :key="index"
                class="
                  navChild
                  d-flex
                  flex-column
                  bg-white
                  text-uppercase
                  w-100
                  border-0
                "
                @click="handleLogout(child.subName)"
              >
                <router-link
                  :to="child.route"
                  class="mobileNav_link d-flex justify-content-start"
                  ><span>{{ child.subName }}</span></router-link
                >
              </b-button>
            </b-card>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  memberMobileNav,
  adminMobileNav,
  guestMobileNav,
} from "../../../navigation/sidebar";
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../../../store/constants";
import axiosIns from "../../../libs/axiosConfig";

export default {
  name: "MobileNav",
  components: {
    guestMobileNav,
    memberMobileNav,
    adminMobileNav,
  },
  data() {
    return {
      isActive: false,
      mobileNav: [],
      member: false,
      id: this.$route.params.area_id,
      favouriteCount: 0,
      message: {
        logout_success: {
          type: "success",
          title: this.$t("logout.success_title"),
          text: this.$t("logout.success_msg"),
        },
      },
    };
  },
  watch: {
    // route_path() {
    //   console.log(this.$route.fullPath)
    //   this.isActive = false
    //   let body = document.getElementsByTagName("BODY")[0]
    //   body.style.height = "unset";
    //   body.style.overflow = "auto";
    // },
    // isActive() {
    // let body = document.getElementsByTagName("BODY")[0]
    //   if(this.isActive){
    //     body.style.height = "100vh";
    //     body.style.overflow = "hidden"
    //   } else {
    //     body.style.height = "unset";
    //     body.style.overflow = "auto"
    //   }
    // }
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    toggleNav() {
      this.isActive = !this.isActive;
    },
    handleLogout(subName) {
      if (subName === "Log out" || subName === "ログアウト") {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          this.$router.push("/login");
          setTimeout(() => {
            this.makeToast("logout_success");
          }, 500);
        });
      }
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.mobileNav = guestMobileNav;
    } else if (this.isAuthenticated && this.getRole == "user") {
      this.mobileNav = memberMobileNav;
    } else {
      this.mobileNav = adminMobileNav;
    }
    axiosIns.get("/user/favouriteCount").then((res) => {
      this.favouriteCount = res.data;
    });
  },
  computed: {
    ...mapGetters(["getRole", "isAuthenticated"]),
    route_path() {
      return this.$route.fullPath
    },
  },
};
</script>

<style lang="scss">
.mobile-nav-button {
  text-align: start !important;
  margin-bottom: 4px !important;
  border-radius: 0 !important;
}

.nav-icon-mobile {
  position: relative;
  padding-right: 10px;
  border-right: 6px solid var(--bg);
  width: 53px;
  height: 33px;
}

.btn-4 {
  margin-bottom: 0 !important;
}

.mobile-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mobile-logo-link {
  width: fit-content;
}
.mobile-header-logo {
  width: 70%;
  height: 100%;
  min-width: 120px;
}

.mobileNav_link:hover {
  text-decoration: none;
  color: inherit;
}
a,
a:hover,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}
.icons {
  @media screen and (min-width: 768px) {
    gap: 10px;
  }
  justify-content: end;
}
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-nav-name {
  white-space: nowrap;
  font-size: 0.8rem;
}
.favourites-number {
  right: 20%;
  bottom: 25%;
  color: black;
  width: 15px;
  height: 15px;
}
// style toggle nav
.sidenav {
  height: fit-content;
  overflow: scroll;
  width: 50%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 1050;
  transition: transform 0.3s ease-in-out;
transform: translateX(100%);
}
.open {
  transform: translateX(0%);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
}
.closebtn {
  position: fixed;
  z-index: 1050;
  top: 1.5%;
  left: 36%;
  transform: translateY(-200%);
  transition: transform 0.3s ease-in-out;
  img {
    max-width: 30px;
    max-height: 30px;
  }
}

.closebtn.active {
  transform: translateY(0);
}

.nav__card {
  .card-body {
    padding: 0;
  }
  .card-header {
    display: flex;
    justify-content: flex-start;
  }
}

.navChild {
  border-width: 0;
  color: black !important;
  padding: 3px 12px !important;
}

.navChild:not(:last-child) {
  border-bottom: 1px dashed var(--success) !important;
}
@media screen and (max-width: 992px) {
  .sidenav {
    height: 100vh;
    overflow-y: scroll;
  }
  .nav__card {
    .card-header {
      display: grid !important;
      grid-template-columns: 1fr 4fr;
      align-items: center;
      img {
        justify-self: center;
      }
      span {
        justify-self: start;
      }
      .mobile-nav-name {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
