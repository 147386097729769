<template>
  <div class="search-bar">
    <b-form class="d-flex" @submit.prevent="handleSubmit">
      <b-form-input
        type="text"
        :placeholder="$t('placeholder')"
        class="search-form"
        v-model="term"
      ></b-form-input>
      <b-button variant="" class="search-button" type="submit">
        <i class="fa fa-search text-black-50" />
      </b-button>
    </b-form>
    <b-modal
      id="modal-search"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      :hide-footer="true"
      :centered="true"
      :hide-header="true"
    >
      <div class="text-center">
        <b-spinner
          variant="success"
          label="Text Centered"
          style="width: 3rem; height: 3rem"
        >
        </b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/libs/axiosConfig";
import { SEARCH } from "@/store/constants";
export default {
  name: "Search",
  data() {
    return { term: "" };
  },
  methods: {
    handleSubmit() {
      this.$bvModal.show("modal-search");
      const text = this.term;
      axios
        .post("/search?page=1&size=6", {
          content: this.term,
        })
        .then((res) => {
          // console.log(res.data);
          this.$store.commit(SEARCH, { result: res.data, term: text });
        });
      // setTimeout(() => {
      //   if (this.$router.history.current.fullPath !== "/search-result") {
      //     this.$router.push("/search-result");
      //   }
      // }, 2000);
    },
  },
  computed: {
    result() {
      return this.$store.state.search_result.properties;
    },
  },
  watch: {
    result() {
      if (this.$store.state.search_result.properties) {
        this.$router.push("/search-result");
        this.$bvModal.hide("modal-search");
      }
    },
  },
};
</script>

<style lang="scss">
.isLoading {
  display: block;
}
.search-form {
  border-radius: 0 !important;
  border: 1px solid !important;
}
.search-button {
  border-width: 1px 1px 1px 0 !important;
  background: transparent !important;
  border-radius: 0 !important;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 1);
}
.modal-content {
  // background: transparent !important;
  border: 0 !important;
}
</style>
