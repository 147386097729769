<template>
  <b-container>
    <div class="col-12 footer-wrapper py-1 px-2 mt-3">
      <ul
        class="
          footer-card
          list-unstyled
          d-flex
          align-items-center
          justify-content-lg-around
          font-weight-bold
          justify-content-end
          py-2
          mb-0
        "
      >
        <li
          v-for="nav in FooterNav"
          :key="nav.name"
          class="me-2 d-none d-lg-flex"
        >
          <b-card
            :header="nav.name"
            header-bg-variant="success"
            class="border-radius-sm single-card"
          >
            <div class="child-wrapper">
              <span
                v-for="(child, index) in nav.children"
                :key="index"
                class="child mt-1"
              >
                <a
                  href="http://sunrisedanangvn.com"
                  v-if="child.href"
                  target="_blank"
                >
                  {{ child.subName }}
                </a>
                <router-link :to="child.route" class="footer_link" v-else>
                  {{ child.subName }}
                </router-link>
              </span>
            </div>
          </b-card>
        </li>
        <li>
          <router-link to="/">
            <img
              src="../../assets/header/logo.png"
              alt="footer-img"
              class="footer-img"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
import {
  memberFooterNav,
  guestFooterNav,
  adminFooterNav,
} from "../../navigation/footer";
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      FooterNav: [],
      member: false,
    };
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.FooterNav = guestFooterNav;
    } else if (this.isAuthenticated && this.getRole === "user") {
      this.FooterNav = memberFooterNav;
    } else {
      this.FooterNav = adminFooterNav;
    }
  },
  methods: {},
  computed: {
    ...mapGetters(["getRole", "isAuthenticated"]),
  },
};
</script>

<style lang="scss">
.button-pc {
  padding: 5px 50px;
  border: 2px solid var(--success);
  filter: drop-shadow(0.9354px 0.9354px 1.7008px rgba(0, 0, 0, 0.4));
}
.footer_link:hover {
  text-decoration: none !important;
  color: inherit;
}

.footer-wrapper {
  // font-size: 11px;
  // background-position: bottom right;
  // background: url("../../assets/footer/m_footer.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // border-top: 2px solid var(--success);
    .card-body {
    padding: 6px;
  }
  .card-header {
    padding: 3px;
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .footer-card {
    gap: 5px;
    min-height: 100px;
  }
  .single-card {
    border: 0 !important;
    font-size: 10px;
    font-weight: 700;
    .card-body {
      border: 2px solid var(--success);
      border-top: 0;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      padding: 6px;
    }
    .card-header {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
  }
  .child-wrapper {
    display: grid;
    grid: repeat(2, 15px) / auto-flow 66px;
  }
  .child {
    border-left: 3px solid var(--success);
    padding-left: 3px;
    display: flex;
    align-items: center;
  }
  .footer-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 250px;
  }

  @media screen and (max-width: 992px) {
    // .footer-wrapper {
    //   font-size: 11px;
    //   background: url("../../assets/footer/footer.jpg");
    //   border-top: 2px solid var(--success);
    // }
  }
}
</style>
