<template>
  <ul class="list-unstyled">
    <li v-for="(nav, index) in topNav" :key="index">
      <router-link
        :to="nav.route"
        class="nav_link"
        v-if="(nav.children == null || nav.children.length == 0) && !nav.href"
      >
        <b-button
          variant="success"
          class="
            sidebar-button
            w-100
            font-weight-bold
            text-uppercase
            d-none d-lg-block
            border-radius-md
            mb-3
          "
          @click="handleLogout(nav.name)"
        >
          <span class="h4 font-weight-bold" style="letter-spacing: 3px"
            >{{ nav.name }}
          </span>
        </b-button>
      </router-link>
      <a href="http://sunrisedanangvn.com" target="_blank" v-else-if="nav.href">
        <b-button
          variant="success"
          class="
            sidebar-button
            w-100
            font-weight-bold
            text-uppercase
            d-none d-lg-block
            border-radius-md
          "
        >
          <span class="h4 font-weight-bold" style="letter-spacing: 3px"
            >{{ nav.name }}
          </span>
        </b-button>
      </a>
      <b-card
        v-else
        header-bg-variant="success"
        class="text-white my-3 border-radius-md sidebar-card"
      >
        <template #header>
          <div class="sidebar-card-header">
            <img v-if="nav.icon" :src="nav.icon.pc" alt="icon" />
            <div v-else></div>
            <span class="h4 font-weight-bold text-uppercase mb-0">{{
              nav.name
            }}</span>
          </div>
        </template>
        <router-link
          :to="child.route"
          class="nav_link"
          v-for="(child, index) in nav.children"
          :key="index"
        >
          <b-button
            class="
              filter-button
              bg-white
              text-success
              mt-2
              border-success border-3
              font-weight-bold
              text-uppercase
              justify-content-center
              border-radius-lg
            "
          >
            <span class="h5 mb-0 font-weight-bold">{{ child.subName }}</span>
          </b-button>
        </router-link>
      </b-card>
    </li>
    <li class="d-lg-none"><NewPost /></li>
    <li class="mt-3"><SocialMedia /></li>
    <li v-for="nav in bottomNav" :key="nav.name">
      <router-link
        :to="nav.route"
        class="nav_link"
        v-if="nav.children == null || nav.children.length == 0"
      >
        <b-button
          variant="success"
          class="
            sidebar-button
            w-100
            mt-3
            font-weight-bold
            text-uppercase
            d-none d-lg-block
            border-radius-md
          "
        >
          <span class="h4 font-weight-bold" style="letter-spacing: 3px">{{
            nav.name
          }}</span>
        </b-button>
      </router-link>
    </li>
  </ul>
</template>

<script>
import NewPost from "@/views/home/components/NewPost.vue";
import Search from "./components/Search.vue";
import SocialMedia from "./components/SocialMedia.vue";
import {
  memberSidebarNav,
  guestSidebarNav,
  adminSidebarNav,
} from "../../navigation/sidebar";
import { AUTH_LOGOUT } from "@/store/constants";
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  components: { Search, SocialMedia, NewPost },
  data() {
    return {
      SidebarNav: [],
      member: false,
      topNav: [],
      bottomNav: [],
      message: {
        logout_success: {
          type: "success",
          title: this.$t("logout.success_title"),
          text: this.$t("logout.success_msg"),
        },
      },
    };
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    handleLogout(name) {
      if (name === "Log out" || name === "ログアウト") {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          setTimeout(() => {
            this.makeToast("logout_success");
          }, 500);
          this.$router.push({ path: "/login" });
        });
      }
    },
    changeSidebar() {
      if (!this.isAuthenticated) {
        this.SidebarNav = guestSidebarNav;
        this.topNav = this.SidebarNav.slice(0, this.SidebarNav.length - 1);
        this.bottomNav = this.SidebarNav.slice(
          this.SidebarNav.length - 1,
          this.SidebarNav.length
        );
      } else if (this.isAuthenticated && this.getRole == "admin") {
        this.SidebarNav = adminSidebarNav;
        this.topNav = this.SidebarNav.slice(0, this.SidebarNav.length - 1);
        this.bottomNav = this.SidebarNav.slice(
          this.SidebarNav.length - 1,
          this.SidebarNav.length
        );
      } else {
        this.SidebarNav = memberSidebarNav;
        this.topNav = this.SidebarNav.slice(0, this.SidebarNav.length - 1);
        this.bottomNav = this.SidebarNav.slice(
          this.SidebarNav.length - 1,
          this.SidebarNav.length
        );
      }
    },
  },
  created() {
    this.changeSidebar();
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapGetters(["getRole", "isAuthenticated"]),
  },
};
</script>

<style lang="scss">
a,
a:hover,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

.card-header {
  text-align: center;
}
.filter-button {
  filter: drop-shadow(0.9354px 0.9354px 1.7008px rgba(0, 0, 0, 0.4));
  white-space: nowrap;
}
.sidebar-card {
  border-width: 0 !important;
  .card-body {
    border: 2px solid var(--success) !important;
    border-top: 0 !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    padding: 2px 12px 8px;
  }
  .card-header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    text-shadow: -1px -1px 1px var(--regis-text-shadow);
    font-size: 1.75rem;
    padding: 0px;
  }
  .sidebar-card-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    gap: 2px;
    white-space: nowrap;
    min-height: 40px !important;
    img {
      justify-self: end;
      position: relative;
      top: -3.5px;
      left: 5px;
      height: 38px;
      width: auto;
    }
    span {
      justify-self: center;
    }
  }
}
.sidebar-button {
  box-shadow: inset 3px 6px 6px -3px var(--sidebar-shadow-blur-white),
    inset -3px -7px 6px -3px var(--sidebar-shadow-green);
  border: 0 !important;
  padding: 12px 20px !important;
  text-shadow: -1px -1px 1px var(--regis-text-shadow);
}
.nav_link:hover {
  text-decoration: none;
  color: inherit;
}

@media screen and (min-width: 992px) {
  .filter-button {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 992px) {
  .sidebar-card .card-body {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px 10px;
  }
  .nav_link {
    button {
      width: 100%;
      margin-top: 0 !important;
    }
  }
  .sidebar-card-header {
    display: grid !important;
    grid-template-columns: 2fr 3fr 2fr !important;
    justify-content: center;
    span {
      justify-self: center !important;
    }
    img {
      justify-self: end;
    }
  }
}

@media screen and (max-width: 320px) {
  .h5 {
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .sidebar-card-header {
    grid-template-columns: 65px 2fr 1fr !important;
    img {
      left: 0 !important;
    }
  }
}
</style>
