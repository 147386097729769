import i18n from "@/libs/i18n";
const forSale = {
  name: i18n.t("sidebar.For Sale"),
  value: "for_sale",
  icon: {
    pc: require("@/assets/icons/sale.png"),
  },
  route: "/",
  children: [
    {
      subName: i18n.t("sidebar.sale.Apartment"),
      route: "/filter?option=for_sale&type=apartment",
    },
    {
      subName: i18n.t("sidebar.sale.House"),
      route: "/filter?option=for_sale&type=house",
    },
  ],
};

export default forSale;
