import forRent from "./forRent";
import forSale from "./forSale";
import byPlan from "./byPlan";
import i18n from "@/libs/i18n";

const memberSidebarNav = [
  {
    name: i18n.t("sidebar.Logout"),
    route: "",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.Favourites"),
    route: "/favourites",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.History"),
    route: "/history",
    icon: null,
    children: [],
  },
  forRent,
  forSale,
  byPlan,
  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "​http://sunrisedanangvn.com​",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: null,
    children: [],
  },
];

const adminSidebarNav = [
  {
    name: i18n.t("sidebar.Logout"),
    route: "",
    icon: { pc: null },
    children: [],
  },
  {
    name: i18n.t("sidebar.Admin"),
    route: "/",
    icon: null,
    children: [
      {
        subName: i18n.t("sidebar.Property"),
        route: "/admin/property",
      },
      {
        subName: i18n.t("sidebar.Post"),
        route: "/admin/post",
      },
      {
        subName: i18n.t("sidebar.Contact"),
        route: "/admin/contact",
      },
      {
        subName: i18n.t("sidebar.customise-carousel"),
        route: "/admin/customise-carousel",
      },
    ],
  },
  forRent,
  forSale,
  byPlan,
  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "​http://sunrisedanangvn.com​",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: null,
    children: [],
  },
];

const guestSidebarNav = [
  {
    name: i18n.t("sidebar.Favourites"),
    route: "/favourites",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.History"),
    route: "/history",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.Login"),
    route: "/login",
    icon: null,
    children: [],
  },
  forRent,
  forSale,
  byPlan,
  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "http://sunrisedanangvn.com",
    icon: null,
    children: [],
  },
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: null,
    children: [],
  },
];

const guestMobileNav = [
  forRent,
  byPlan,
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: { mobile: require("@/assets/icons/mobileNav/contact.png") },
    children: [],
  },
  {
    name: i18n.t("sidebar.Member Only"),
    route: "/",
    icon: { mobile: require("@/assets/icons/mobileNav/member.png") },
    children: [
      {
        subName: i18n.t("sidebar.Login"),
        route: "/login",
      },
    ],
  },
  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "http://sunrisedanangvn.com",
    icon: { mobile: require("@/assets/icons/mobileNav/company.png") },
    children: [],
  },
];

const memberMobileNav = [
  forRent,
  byPlan,
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: { mobile: require("@/assets/icons/mobileNav/contact.png") },
    children: [],
  },
  {
    name: i18n.t("sidebar.Member Only"),
    route: "/",
    icon: { mobile: require("@/assets/icons/mobileNav/member.png") },
    children: [
      {
        subName: i18n.t("sidebar.Logout"),
        route: "/",
      },
    ],
  },
  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "http://sunrisedanangvn.com",
    icon: { mobile: require("@/assets/icons/mobileNav/company.png") },
    children: [],
  },
];

const adminMobileNav = [
  {
    name: i18n.t("sidebar.Admin"),
    route: "/",
    icon: { mobile: require("@/assets/icons/mobileNav/member.png") },
    children: [
      {
        subName: i18n.t("sidebar.Property"),
        route: "/admin/property",
      },
      {
        subName: i18n.t("sidebar.Post"),
        route: "/admin/post",
      },
      {
        subName: i18n.t("sidebar.Contact"),
        route: "/admin/contact",
      },
      {
        subName: i18n.t("sidebar.Logout"),
        route: "/",
      },
    ],
  },
  forRent,
  byPlan,
  {
    name: i18n.t("sidebar.Contact Us"),
    route: "/contact-us",
    icon: { mobile: require("@/assets/icons/mobileNav/contact.png") },
    children: [],
  },

  {
    name: i18n.t("sidebar.Company Profile"),
    route: "",
    href: "http://sunrisedanangvn.com",
    icon: { mobile: require("@/assets/icons/mobileNav/company.png") },
    children: [],
  },
];

export {
  memberSidebarNav,
  guestSidebarNav,
  adminSidebarNav,
  guestMobileNav,
  memberMobileNav,
  adminMobileNav,
};
