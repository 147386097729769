<template>
  <div>
    <div class="social-network border-radius-md mx-auto">
      <div class="border-3 border-success fb w-100 text-center">
        <div
          class="fb-page d-none d-lg-block"
          data-href="https://www.facebook.com/sunrisedanang88/"
          data-tabs="timeline"
          data-width="500"
          data-height="255"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/sunrisedanang88/"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/sunrisedanang88/"
              >ダナンおすすめ観光情報 Sunrise Danang</a
            >
          </blockquote>
        </div>

        <div
          class="fb-page d-lg-none"
          data-href="https://www.facebook.com/sunrisedanang88/"
          data-tabs="timeline"
          data-width="500"
          data-height="400"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/sunrisedanang88/"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/sunrisedanang88/"
              >ダナンおすすめ観光情報 Sunrise Danang</a
            >
          </blockquote>
        </div>
      </div>

      <div
        class="
          d-flex
          justify-content-around
          social-icons
          mt-3
          w-100
          social-icons
        "
      >
        <a
          href="https://line.me/R/ti/p/@738mcjzt" target="_blank"
        >
          <img src="@/assets/icons/line.png" alt="line" class="social-icon" />
        </a>
        <a href="https://www.facebook.com/sunrisedanang88/" target="_blank">
          <img src="@/assets/icons/fb.png" alt="fb" class="social-icon" />
        </a>
        <a href="https://www.instagram.com/sunrise_danang/?hl=ja" target="_blank">
          <img src="@/assets/icons/insta.png" alt="insta" class="social-icon" />
        </a>
        <a href="https://twitter.com/sunrise_danang" target="_blank">
          <img
            src="@/assets/icons/twitter.png"
            alt="twitter"
            class="social-icon"
          />
        </a>
        <a href="https://www.youtube.com/c/SunriseDanang/featured" target="_blank">
          <img
            src="@/assets/icons/youtube.png"
            alt="youtube"
            class="social-icon"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  mounted() {
    // must call this to reload Facebook SDK every time component mounted
    window.FB.XFBML.parse();
  },
};
</script>

<style lang="scss">
.social-network {
  max-width: 550px;
}
.social-icon {
  width: 100%;
  height: auto;
  max-width: 35px;
}

// facebook plugin style

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
  width: 100% !important;
}

._2p3a {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .social-network {
    border: 2px solid var(--success);
    padding: 10px;
  }
}
@media screen and (max-width: 992px) {
  .fb {
    border: 2px solid var(--success);
    border-radius: 0.75rem;
    padding: 20px;
  }
  .social-icons {
    padding: 20px;
  }
}
</style>
