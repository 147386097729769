import i18n from "@/libs/i18n";
const forRent = {
  name: i18n.t("sidebar.For Rent"),
  value: "for_rent",
  icon: {
    pc: require("@/assets/icons/home.png"),
    mobile: require("@/assets/icons/mobileNav/login.png"),
  },
  route: "/",
  children: [
    {
      subName: i18n.t("sidebar.rent.Apartment"),
      route: "/filter?option=for_rent&type=apartment&page=1",
    },
    {
      subName: i18n.t("sidebar.rent.Penthouse"),
      route: "/filter?option=for_rent&type=pent-house&page=1",
    },
    {
      subName: i18n.t("sidebar.rent.Villa"),
      route: "/filter?option=for_rent&type=villa&page=1",
    },
    {
      subName: i18n.t("sidebar.rent.Office"),
      route: "/filter?option=for_rent&type=office&page=1",
    },
  ],
};

export default forRent;
