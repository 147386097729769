import i18n from "@/libs/i18n";

const byPlan = {
  name: i18n.t("sidebar.Choose By Plan"),
  value: "plan",
  icon: {
    pc: require("@/assets/icons/map.png"),
    mobile: require("@/assets/icons/mobileNav/map.png"),
  },
  route: "/",
  children: [
    { subName: i18n.t("sidebar.layout.studio"), route: "/filter?plan=studio" },
    { subName: "1LDK", route: "/filter?plan=1ldk" },
    { subName: "2LDK", route: "/filter?plan=2ldk" },
    { subName: "3LDK", route: "/filter?plan=3ldk" },
    { subName: "4LDK", route: "/filter?plan=4ldk" },
    { subName: "1DK", route: "/filter?plan=1dk" },
    { subName: "1K", route: "/filter?plan=1k" },
    { subName: "2K", route: "/filter?plan=2k" },
    { subName: "2DK", route: "/filter?plan=2dk" }
  ],
};

export default byPlan;
