<template>
  <div class="new-post col-12 mt-3 p-0">
    <div class="card new-post__card border-radius-none border-0">
      <div
        class="
          card-header
          text-white
          bg-success
          h4
          text-center text-lg-left
          font-weight-bold
        "
      >
        {{ $t("property_info.articles") }}
      </div>
      <b-card-body
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 420px; overflow-y: scroll"
      >
        <div v-for="(post, id) in posts" :key="id" class="single-post">
          <div class="row">
            <div class="col-6 col-lg-4">
              <router-link :to="'/post/' + post._id">
                <img
                  alt="thumbnail"
                  :src="url + post.thumbnail"
                  class="w-100 post-image"
                />
              </router-link>
            </div>
            <div class="col-6 col-lg-8">
              <h6 class="post-createdAt">{{ post.createdAt | format1 }}</h6>

              <router-link :to="'/post/' + post._id">
                <h6 class="post-title">{{ post.title }}</h6></router-link
              >
              <hr class="hr1" />
              <p class="content d-none d-md-block" v-html="post.content"></p>
            </div>
          </div>
          <hr class="hr2" />
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import axios from "../../../libs/axiosConfig";

export default {
  name: "new-post",
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      posts: [],
    };
  },
  created() {
    axios
      .get("/post-limit")
      .then((response) => {
        this.posts = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.new-post {
  margin-bottom: 30px;
  .new-post__card {
    border-radius: 0 !important;
    .card-body {
      border: 3px solid var(--success);
      border-top: 0;
      .card-header {
        border-radius: 0 !important;
      }
      .single-post {
        padding-bottom: 20px;
        .post-image {
          height: 130px;
          object-fit: cover;
        }
        .row {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          .col-lg-4 {
            padding: 5px;
          }
          .col-lg-8 {
            .hr1 {
              border-top: 0.07cm solid var(--success);
              opacity: 1;
              color: var(--success);
              margin-top: 0px;
            }
            .content {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 25px;
              -webkit-line-clamp: 2;
              height: 50px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              margin-bottom: 0;
              justify-self: flex-start;
              font-weight: bold;
            }
            .post-title {
              font-weight: bold;
              text-transform: uppercase;
              color: var(--post-title-blue);
              margin-bottom: 3px !important;
            }
            a:hover {
              text-decoration: none;
            }
            .post-createdAt {
              font-weight: bold;
            }
          }
        }
        .hr2 {
          border-top: 0.07cm solid var(--success);
          opacity: 1;
          color: var(--success);
          margin-left: -15px;
          width: 105%;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .post-image {
      height: 100px !important;
      object-fit: cover;
    }
  }
}
</style>
