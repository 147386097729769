import byPlan from "./byPlan";
import forRent from "./forRent";
import forSale from "./forSale";
import i18n from "@/libs/i18n";

const memberFooterNav = [
  forRent,
  byPlan,
  forSale,
  {
    name: i18n.t("sidebar.Company"),
    icon: null,
    children: [
      {
        subName: i18n.t("sidebar.Company Profile"),
        route: "",
        href: "​http://sunrisedanangvn.com",
      },
      { subName: i18n.t("sidebar.Contact Us"), route: "/contact-us" },
      { subName: i18n.t("sidebar.Favourites"), route: "/favourites" },
      { subName: i18n.t("sidebar.History"), route: "/history" },
    ],
  },
];

const adminFooterNav = [
  forRent,
  byPlan,
  forSale,
  {
    name: i18n.t("sidebar.Company"),
    icon: null,
    children: [
      {
        subName: i18n.t("sidebar.Company Profile"),
        route: "",
        href: "​http://sunrisedanangvn.com",
      },
    ],
  },
];

const guestFooterNav = [
  forRent,
  byPlan,
  {
    name: i18n.t("sidebar.Member Only"),
    icon: null,
    children: [
      { subName: i18n.t("sidebar.History"), route: "/history" },
      { subName: i18n.t("sidebar.Login"), route: "/login" },
      { subName: i18n.t("sidebar.Favourites"), route: "/favourites" },
    ],
  },
  {
    name: i18n.t("sidebar.Company"),
    icon: null,
    children: [
      {
        subName: i18n.t("sidebar.Company Profile"),
        route: "",
        href: "​http://sunrisedanangvn.com​",
      },
      { subName: i18n.t("sidebar.Contact Us"), route: "/contact-us" },
    ],
  },
];

export { memberFooterNav, guestFooterNav, adminFooterNav };
